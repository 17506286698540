:root {
  --bd-callout-link: 10, 88, 202;
  --bd-callout-code-color: #ab296a;
}

body{
  font-family: 'Lexend Deca', sans-serif;
}

iframe[src^="https://www.youtube"] {
  height: auto;
  width: 100%;
  aspect-ratio: 16 / 9;
  max-width: 960px;
}

p, li {
  text-wrap: pretty;
}

.form-control::placeholder {
  opacity: .3;
}

.password-strength__indicator {
  &.is-strong {
    background-color: var(--bs-success);
  }
  &.is-weak {
    background-color: var(--bs-danger);
  }
}

.transition-fade {
  transition: opacity 1s ease-in-out;
  opacity: 0;
}

.transition-fade.fade-in {
  opacity: 1;
}

.fix-width-14px {
  width: 14px;
}

.fix-width-16px {
  width: 16px;
}

.fix-width-20px {
  width: 20px;
}

.fix-width-22px {
  width: 22px;
}

.min-width-80px {
  min-width: 80px;
}

.min-width-100px {
  min-width: 100px;
}

.min-width-120px {
  min-width: 120px;
}

.min-width-140px {
  min-width: 140px;
}

.card-header-menu {
  width: 35px;
  height: 35px;
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x) .5rem var(--bs-card-cap-padding-x);
}

.callout {
  --bs-link-color-rgb: var(--bd-callout-link);
  --bs-code-color: var(--bd-callout-code-color);
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  color: var(--bd-callout-color, inherit);
  background-color: var(--bd-callout-bg, var(--bs-gray-100));
  border-left: 0.25rem solid var(--bd-callout-border, var(--bs-gray-300));
}

.callout-info {
  --bd-callout-color: var(--bs-info-text-emphasis);
  --bd-callout-bg: var(--bs-info-bg-subtle);
  --bd-callout-border: var(--bs-info-border-subtle);
}

.callout-warning {
  --bd-callout-color: var(--bs-warning-text-emphasis);
  --bd-callout-bg: var(--bs-warning-bg-subtle);
  --bd-callout-border: var(--bs-warning-border-subtle);
}

.callout-danger {
  --bd-callout-color: var(--bs-danger-text-emphasis);
  --bd-callout-bg: var(--bs-danger-bg-subtle);
  --bd-callout-border: var(--bs-danger-border-subtle);
}

.screen-darken {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(10, 18, 71, 0.75);
  pointer-events: none;
  z-index: 2;
  opacity: 1;
  visibility: visible;
  position: fixed;
}

.modal-backdrop.show {
  opacity: 1;
}

aside.sidebar--right {
  .block--ansprechpartner {
    .block--ansprechpartner-item {
      width: 100%;
    }
  }
}

.hyphens-auto {
  word-wrap: break-word;
  overflow-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

/* RIPPLE EFFECT */

.btn, .ripple-init, .ripple-wrapper {
  position: relative;
  overflow: hidden;
}

.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple-light 1s ease-out;
  opacity: 0;
  pointer-events: none; /* Blockiert den Klick auf das Input nicht */
}

.ripple-init-dark .ripple,
.btn-link .ripple {
  animation: ripple-dark 1s ease-out !important;
}

@keyframes ripple-light {
  0% {
    opacity: .4;
    transform: scale(0);
    background: rgba(255, 255, 255, .75);
  }
  
  100% {
    opacity: 0;
    transform: scale(4);
    background: rgba(255, 255, 255, .75);
  }
}

@keyframes ripple-dark {
  0% {
    opacity: .4;
    transform: scale(0);
    background: rgba(10, 18, 71, 0.75);
  }
  
  100% {
    opacity: 0;
    transform: scale(4);
    background: rgba(10, 18, 71, 0.75);
  }
}

.offcanvas {
  transition: transform 0.5s ease-in-out; /* 0.5s for a smooth slide-in effect */
}

span.nav-link {
  cursor: pointer;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -9px;
}

.dropdown-submenu > .dropdown-toggle::after {
  border-bottom: 0;
  border-left: .3rem solid transparent;
  border-right: .3rem solid transparent;
  border-top: .3rem solid;
  content: "";
  display: inline-block;
  margin-left: .255rem;
  vertical-align: .255rem;
  transform: rotate(-0.25turn);
}

.dropdown-submenu:hover .dropdown-menu {
  display: block;
}


/* ============ News-Teaser ============ */
.news-teaser-mediacontainer {
  width: 96px;

  .ratio {
    img {
      object-fit: cover;
    }
  }
}

.article--teaser {
  .card {
    //background-color: rgba(var(--bs-pax-secondary-tint-90-rgb), 1);
    //background-color: var(--bs-pax-secondary-tint-95);
    background-color: white;
    &:hover, &:focus {
      //background-color: rgba(var(--bs-secondary-rgb),.1);
      //background-color: var(--bs-pax-secondary-tint-90);
      background-color: var(--bs-light);
    }
  }
}

/* ============ News-Teaser ============ */

p:last-child {
  margin-bottom: 0 !important;
}

.main-content-wrapper {
  width: 100%;
  position: relative;
}

@include media-breakpoint-up("sm") {
  header form {
    width: 20rem;
  }
}

@include media-breakpoint-up("md") {
  header form {
    width: 30rem;
  }
}

@include media-breakpoint-up("lg") {
  header form {
    width: 35rem;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

}

b,
strong,
.fw-bold {
  font-weight: 600 !important;
}

a {
  text-decoration: none;
}

.hyphens-auto {
  hyphens: auto;
}

.navbar-toggler:focus {
  box-shadow: none;
}

a.list-group-item:hover {
  cursor: pointer;
}

.list-group-item:hover .dislay-hover {
  display: inline-block !important;
  font-size: 0.75rem;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(195 25 36 / 70%);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgb(195 25 36 / 0%);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(195 25 36 / 0%);
  }
}


/* ============ Main Content ============ */


@include media-breakpoint-up(sm) { 

}
@include media-breakpoint-up(md) { 

}
@include media-breakpoint-up(lg) { 

}
@include media-breakpoint-up(xl) { 

}
@include media-breakpoint-up(xxl) { 
  
}

/* ============ Dropdown Menü ============ */

.navbar-nav--account .dropdown-menu {
  position: absolute;
  right: 0;
  left: auto;
}

.dropdown-large {
  padding: 0.75rem;
}

.dropstart--center .dropdown-menu {
  right: 100% !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

/* ============ Dropdown Menü - Desktop ============ */
@include media-breakpoint-up("md") {
  .dropdown-large {
    min-width: 600px;
  }
}

/* ============ Dropdown Menü .end// ============ */

/* ============ Werbemittelshop ============ */

.card-wmshop-detail {
  lable.form-label {
    font-weight: 500;
    color: var(--bs-tertiary-color);
  }
  .form-actions {
    text-align: right;
  }
  input[type="submit"] {
    margin-top: .5rem;
    margin-left: auto;
  }
}

.card-list-wmshop {
  cursor: pointer;

  &:hover, &:focus {
    background-color: rgba(var(--bs-secondary-rgb), .1);
  }
}

img.card-img-wmshop {
  height: 220px;
  width: 100%;
  object-fit: contain;
}

/* ============ Suche in Modal ============ */
.search-clear {
  z-index: 100;
  right: 1.5rem;
  margin-top: -24px;
}

/*
.wrapper:before {
  background: $paxblau;
  content: " ";
  height: 264px;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -1;
}
*/

.navbar-nav--shortcuts {
  .nav-pills .nav-link {
    background-color: var(--bs-light-bg-subtle);
  }
}

/* ============ Facets ============ */

.facets-reset {
  color: var(--bs-primary);

  &:hover,
  &:focus {
    color: var(--bs-primary);
  }

  input {
    display: none;
  }
}

.facets-reset::before,
.facet-summary-item--clear > a::before {
  content: "\f2ed";
  font-weight: 900;
  font-family: "Font Awesome 6 Free", sans-serif;
  margin-right: .5rem;
}

.facet-item {
  .facets-checkbox {
    margin-right: .5rem;
  }
  label {
    cursor: pointer;

    &::after {
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}

.facets-soft-limit-link {
  padding: .375rem .75rem;
  line-height: 1.5;
  color: var(--bs-link-color);
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  display: inline-block;
}

/* ============ Responsive Video ============ */

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 0;
  height: 0;
  overflow: hidden;
}
.video-container iframe,
.video-container object,
.video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* ============ AJAX ============ */

@keyframes bs-throbber {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

// Normal Throbber
.ajax-progress {
  &__throbber {
    border: 3px solid var(--bs-primary);
    border-right: 3px dotted transparent;
  }

  .ajax-progress__message {
    color: #545560;
  }
}

// Media library Throbber
.media-library-widget {
  .ajax-progress__throbber {
    border: 2px solid var(--bs-primary);
    border-right: 2px dotted transparent;
  }
}

// UI Dialog Throbber
.ui-dialog .ajax-progress-throbber {
  padding: .5rem;
  background: rgb(248,250,254);
  border-radius: 50%;
  box-shadow: 0 2px 6px 0 rgba(34, 35, 48, 0.1);
  border: 1px solid rgba(216, 217, 224, 0.8);
}

.ui-dialog .ajax-progress-throbber,
.contextual-links .ajax-progress-throbber {
  &::before {
    content: "";
    display: block;
    position: absolute;
    top: .5rem;
    left: .5rem;
    width: 1.125rem;
    height: 1.125rem;
    border: 2px solid var(--bg-primary);
    border-right: 2px dotted transparent;
    border-radius: 50%;
    animation: bs-throbber 0.75s linear infinite;
  }
}

.ajax-progress__throbber--fullscreen,
.ui-dialog .ajax-progress__throbber,
.media-library-item .ajax-progress__throbber {
  @extend .ajax-progress__throbber;
}

.contextual-links {
  li {
    position: relative;
  }

  .ajax-progress-throbber {
    position: absolute;
    top: 0;
    right: .5rem;
    width: 24px;
    height: 24px;

    &::before {
      top: 0;
      left: .25rem;
    }
  }
}

.claro-autocomplete__message {
  color: var(--bs-primary);
}

/* ============ Footer ============ */

/* Wenn das Icon VOR dem Link-Text ist */
.navbar-footer {
  .nav-link i + .link-text {
    margin-left: 0.5rem; /* Abstand nach links */
  }

  .nav-link .link-text + i {
    margin-left: 0.5rem; /* Abstand nach links */
  }

  /* Wenn das Icon NACH dem Link-Text ist */
  .nav-link .link-text + i {
    margin-left: 0.5rem; /* Abstand nach links */
  }

  .nav-link i + .link-text {
    margin-right: 0.5rem; /* Abstand nach rechts */
  }
}

/* ============ Akademie ============ */

.list-group {
  // Allgemeine Stile für die Listengruppe
}

.akademie-list {
  &__item {

    // Zustandsspezifischer Modifikator
    &.col {
      
    }
  }

  &__item-wrapper {

  }

  &__date {
    min-width: 65px;
    text-align: center;

    // Anpassungen für mobile Ansichten
    @include media-breakpoint-up(md) {
      min-width: 100px;
    }

    &-day,
    &-month,
    &-year {
      
    }

    &-day {
      
    }

    &-month,
    &-year {
      
    }
  }

  &__description {

    &-name {
      
    }
  }

  &__location,
  &__fee {
    
  }

  &__link {

    &-details {
      
    }
  }
}

/* ============ Dashboard-Icons ============ */

.icon-container-outer {
  width: 33.3333%;
}

@include media-breakpoint-up("sm") {
  .icon-container-outer {
    width: 25%;
  }
}

@include media-breakpoint-up("md") {
  .icon-container-outer {
    width: 25%;
  }
}

@include media-breakpoint-up("lg") {
  .icon-container-outer {
    width: 33.3333%;
  }
}

@include media-breakpoint-up("xl") {
  .icon-container-outer {
    width: 33.3333%;
  }
}

@include media-breakpoint-up("xxl") {
  .icon-container-outer {
    width: 25%;
  }
}

/* ============ Buttons - Icons ============ */

.btn-icon {
  width: 40px; 
  height: 40px; 
  padding: 0 !important;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-solid {
  font-weight: 900 !important;
  font-family: Font Awesome\ 6 Free !important;
}

/* Checkbox Switch */

.form-check.form-switch {
  min-height: 0;
  padding-left: 3rem;
  margin-bottom: 0;
}

.form-switch .form-check-input {
  width: 3rem;
  height: 1.75rem;
  margin-left: -3rem;
}

/* Lightbox - GLightbox */

.glightbox-clean .gslide-title {
  color: white !important;
  font-family: inherit !important;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.4rem;
  margin-bottom: 1rem;
}

.glightbox-clean .gslide-desc {
  color: white !important;
  font-family: inherit !important;
  font-size: 1rem;
  font-weight: 400;
}

.glightbox-clean .gslide-description {
  background: var(--bs-pax-secondary);
}

.glightbox-clean .gdesc-inner {
  padding: 1rem;
}

/*
.glightbox:hover {
  display: block;
  position: relative;
}

.glightbox:hover::after {
  content: '\f002';
  font-weight: 900 !important;
  font-family: Font Awesome\ 6 Free !important;
  font-size: 1.5rem;
  color: white;
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glightbox:hover::before {
  content: '';
  position: absolute;
  left: 0; top: 0; right: 0; bottom: 0;
  background-color: rgba(var(--bs-pax-secondary-rgb), .50);
  width: 100%;
  height: 100%;
  border: 2px solid rgba(var(--bs-pax-secondary-rgb), 1);
  z-index: 1;
}
*/

.btn-page-feedback {
  width: 60px; 
  height: 60px; 
  right: 1rem; 
  bottom: 1rem; 
  background-color: var(--bs-pax-primary); 
  border-color: white
}

@media (min-width: 992px) {
  .btn-page-feedback {
    right: 1.5rem;
  }
}

@media (min-width: 1200px) {
  .btn-page-feedback {
    right: 3rem;
  }
}