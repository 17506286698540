#mmenu:not(.mm-menu) {
  display: none !important;
}

.mm-menu {
  z-index: 502;
}

.mm-menu--theme-dark {
  --mm-color-background: var(--bs-dark);
}

.mm-menu--position-left,
.mm-menu--position-left-front,
.mm-menu--position-right,
.mm-menu--position-right-front {
  width: 100%;
}

.mm-menu--iconbar-left .mm-iconbar {
  background-color: var(--bs-pax-secondary);
  color: var(--bs-light);
}

.mm-iconbar__bottom {
  padding-bottom: 80px;
}

.mm-menu--iconbar-left .mm-iconbar .mm-iconbar__top::before {
  content: "";
  width: 46px;
  height: 50px;
  display: flex;
  margin: 0 2px;
  background-image: url(../../logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.mm-menu {
  --mm-listitem-size: 56px;
  font-size: 1.1rem;
}

.mm-listitem--selected {
  color: var(--bs-primary);
}

.mm-btn--close::before {
  content: "×";
  content: "\f00d";
  font-family: "Font Awesome 6 Free", sans-serif;
  font-weight: bold;
}

.mm-listitem__text i,
.mm-listitem__text svg {
  width: 1.4rem !important;
  margin-right: 0.75rem !important;
}

.mm-iconbar__top a:active,
.mm-iconbar__top a:hover,
.mm-iconbar__top a:visited,
.mm-iconbar__top a:focus,
.mm-iconbar__bottom a:active,
.mm-iconbar__bottom a:hover,
.mm-iconbar__bottom a:visited,
.mm-iconbar__bottom a:focus {
  background-color: var(--bs-primary-border-subtle);
}

.mm-menu--theme-dark {
  --mm-color-background-emphasis: rgb(0, 0, 0, 0.5);
}

.mm-menu--selected-parent .mm-listitem__btn,
.mm-menu--selected-parent .mm-listitem__text {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mm-listitem .mm-listitem__btn:hover,
.mm-listitem .mm-listitem__text:hover,
.mm-listitem .mm-listitem__btn:focus,
.mm-listitem .mm-listitem__text:focus {
  color: var(--bs-primary) !important;
  background: var(--mm-color-background-emphasis) !important;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.mm-menu--selected-parent .mm-listitem__btn:hover,
.mm-menu--selected-parent .mm-listitem__text:hover,
.mm-menu--selected-parent .mm-listitem__btn:focus,
.mm-menu--selected-parent .mm-listitem__text:focus {
  color: var(--bs-primary) !important;
  background: var(--mm-color-background-emphasis) !important;
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
